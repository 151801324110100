.container {
    margin-top: 25.5%;
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 3;
    border-radius: 15px; /* Adjust the value as needed */
    overflow: hidden; /* Ensure content stays within rounded corners */
}

.aboutMeSection {
    text-align: left;
    font-family: 'Open Sans';
    max-width: 90%;
    margin: 0 auto;
    padding: 50px 80px;
}

.title {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.description {
    font-size: 24px;
    font-weight: lighter;
    line-height: 1.5;
    text-align: justify;
}

@media (max-width: 980px) {
    .container {
        margin-top: 4%;
    }
}

@media (max-width: 768px) {
    .container {
        margin-top: 4%;
    }
    .title {
        font-size: 22px;
    }

    .description {
        font-size: 18px;
    }
}

@media (max-width: 576px) {
    .container {
        margin-top: 4%;
    }
    
    .title {
        font-size: 22px;
        margin-bottom: 10px;
    }

    .description {
        font-size: 18px;
    }

    .aboutMeSection {
        padding: 25px 35px;
    }
}
