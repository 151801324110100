/* welcomeLight.module.css */

.container {
  background-color: #ffffff; /* Light mode background color */
  color: #000; /* Light mode text color */
}

.text {
  color: #000; /* Light mode text color */
}

.ghulamMujtaba {
  color: #000; /* Light mode text color */
}

.paragraph {
  color: #000; /* Light mode text color */
}
