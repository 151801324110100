

/* Skills section title */
.skillsTitle {

    color: #191d23;
 
}

/* Individual skill card */
.skillCard {
   
    background-color: #e8ebee; /* Background color of the skill card */

}

/* Header styling */
.header {

    color: #191d23;
    
}





/* Container for skill name and progress bar */
.skillNameContainer {
   
    background-color: #ffffff; /* Background color for the skill name container */

}



/* Progress bar */
.progress {

    background-color: #272c34;
  
}

/* Progress bar widths */
.progress70 { width: 70%; }
.progress80 { width: 80%; }
.progress60 { width: 60%; }
.progress90 { width: 90%; }
.progress85 { width: 85%; }
.progress75 { width: 75%; }
.progress65 { width: 65%; }




/* Media query for smaller screens */
@media (max-width: 768px) {
    /* Adjustments for smaller screens */
    .skillFrame {
        justify-content: center; /* Center align skill cards */
    }
    
    .skillsTitle {
        padding-left: 3%; /* Adjust padding for smaller screens */
    }
}
