/* welcomeDark.module.css */

.darkContainer {
 
  color: #ffffff; /* Dark mode text color */
}

.text {
  color: #ffffff; /* Dark mode text color */
}

.ghulamMujtaba {
  color: #ffffff; /* Dark mode text color */
}

.paragraph {
  color: #ffffff; /* Dark mode text color */
  
}
